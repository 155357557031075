const category = [
  {
    id: "158",
    title: "投资入门",
    children: [
      {
        id: 161,
        title: "开户",
      },
      {
        id: 162,
        title: "出入金",
      },
      {
        id: 163,
        title: "交易",
      },
      {
        id: 164,
        title: "名词解释",
      },
    ],
  },
  {
    id: "159",
    title: "投资进阶",
    children: [
      {
        id: 165,
        title: "交易指导",
      },
      {
        id: 166,
        title: "盈利计算",
      },
      {
        id: 167,
        title: "产品介绍",
      },
      {
        id: 172,
        title: "专家评论",
      },
    ],
  },
  {
    id: "160",
    title: "投资高手",
    children: [
      {
        id: 168,
        title: "技巧学习",
      },
      {
        id: 169,
        title: "专家观点",
      },
      {
        id: 170,
        title: "教学专题",
      },
      {
        id: 171,
        title: "推荐有奖",
      },
    ],
  },
  {
    id: "173",
    title: "技术指标",
    children: [
      {
        id: 174,
        title: "指标分析",
      },
    ],
  },
  {
    id: "226",
    title: "帮助中心",
    children: [
      {
        title: "虚拟币",
        id: 233,
      },
      {
        title: "账户相关",
        id: 234,
      },
      {
        title: "合约指南",
        id: 235,
      },
      {
        title: "充值提现",
        id: 236,
      },
      {
        title: "如何下载",
        id: 237,
      },
    ],
  },
  {
    id: "228",
    title: "公告",
    children: [
      {
        title: "官网公告",
        id: "228",
      },
    ],
  },
];

const limitNews = 5;
let linkConfig = {
  h5transaction: "https://mtrade.anto168.com/?utm_source=H5",
  msite: "",
  mis: "https://mis.atbground.com",
  online: "https://antofx.kf5.com/kchat/1040026#/",
  download: {
    ios: {
      anto: "https://apps.apple.com/cn/app/id6473134887",
      locale: "https://apps.apple.com/app/apple-store/id1557613903",
    },
    android: {
      anto: ["https://downloads.allkadull.com/source/material//MJJY_GW.apk"],
      anto_backup:
        "https://downloads.allkadull.com/source/material//MJJY_GW.apk",
    },
  },
};
if (process.env.BUILD_ENV === "uat") {
  linkConfig.h5transaction = "https://mtrade.atbgovernor.com/?utm_source=H5";
  linkConfig.msite = "https://m.atbgovernor.com";
  linkConfig.mis = "https://mis.atbgovernor.com";
}

if (process.env.BUILD_ENV !== "uat") {
  const hostname = window.location.hostname;
  const hostnameArr = hostname.split(".");
  const mainHost = hostnameArr.length === 2 ? hostnameArr[0] : hostnameArr[1];
  const needModifyUrl = ["h5transaction"];
  for (const key of needModifyUrl) {
    if (linkConfig[key]) {
      linkConfig[key] = linkConfig[key].replace(
        /\.[A-Za-z0-9_]+\./,
        `.${mainHost}.`
      );
    }
  }
}

const webCfg = {
  btcc: {
    copyright: "BTCC Global",
  },
  jn: {
    copyright: "金牛财富",
  },
  btcchy: {
    copyright: "BTCC",
  },
  btccgl: {
    copyright: "BTCC Global",
  },
  anto: {
    copyright: "Anto Global",
  },
  huangsha: {
    copyright: "黄沙金业",
  },
  huixin: {
    copyright: "汇鑫贵金属",
  },
  meijian: {
    copyright: "美建金业",
  },
};

export { limitNews, linkConfig, category, webCfg };
